import React from 'react';
import './Allprojects.css';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import Project1 from '../../assets/AllProject/project1.png';
import Project2 from '../../assets/AllProject/project2.png';
import Project3 from '../../assets/AllProject/project3.png';
import Project4 from '../../assets/AllProject/project4.png';
import Project5 from '../../assets/AllProject/project5.png';
import Project6 from '../../assets/AllProject/project6.png';
import Project7 from '../../assets/AllProject/project7.png';
import Project8 from '../../assets/AllProject/project8.png';
import Project9 from '../../assets/AllProject/project9.png';

function Allprojects() {
  const projects = [
        Project1,
        Project2,
        Project3,
        Project4,
        Project5,
        Project6,
        Project7,
        Project8,
        Project9
  ];

  return (
    <Container className="app-container text-white" style={{ marginTop: '100px' }}>
      <Row>
        <Col md={9} className="gallery-project">
          <Row>
            {projects.map((project, index) => (
              <Col key={index} sm={12} md={6} lg={4} className="mb-4">
                <img src={project} alt={`Project ${index + 1}`} className="img-fluid" />
              </Col>
            ))}
          </Row>
        </Col>
        <Col md={3} className=' sidebar-section'>
          <h3>Our Projects</h3>
          <div className="sidebar p-4">
            <ListGroup variant="flush">
              {/* <ListGroup.Item action variant="dark" style={{color: "#fff"}}>Section</ListGroup.Item> */}
              <ListGroup.Item action variant="dark">
                All
              </ListGroup.Item>
              <ListGroup.Item action variant="dark">
                Residential
              </ListGroup.Item>
              <ListGroup.Item action variant="dark">
              Commercial
              </ListGroup.Item>
            </ListGroup>
          </div>
          <button class="hover-button"><span>Build Your Dream</span></button>       


        </Col>
      </Row>
    </Container>
  );
}

export default Allprojects;
