// src/components/Gallery.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ListGroup } from 'react-bootstrap';
import './Allproducts.css';


const Allproducts = () => {
  const navigate = useNavigate(); // Initialize navigate function
  const [selectedCategory, setSelectedCategory] = useState('');
  const singleProductDetail = [{
    name: 'Cascading Keys',
    images: ['assets/Allproducts/MetaForm/CascadingKeys/cascading1.png',
      'assets/Allproducts/MetaForm/CascadingKeys/cascading2.png',
      'assets/Allproducts/MetaForm/CascadingKeys/cascading3.png',
      'assets/Allproducts/MetaForm/CascadingKeys/cascading4.png',
      'assets/Allproducts/MetaForm/CascadingKeys/cascading5.png',
      'assets/Allproducts/MetaForm/CascadingKeys/cascading6.png',
    ]
  }, {
    name: 'metacassette',
    images: ['assets/Allproducts/MetaForm/metacassette/metacassette1.jpg',
      'assets/Allproducts/MetaForm/metacassette/metacassette2.png',
      'assets/Allproducts/MetaForm/metacassette/metacassette3.jpg',
      'assets/Allproducts/MetaForm/metacassette/metacassette4.png',
      'assets/Allproducts/MetaForm/metacassette/metacassette5.jpg',
      'assets/Allproducts/MetaForm/metacassette/metacassette6.png',
      'assets/Allproducts/MetaForm/metacassette/metacassette7.jpg',
      'assets/Allproducts/MetaForm/metacassette/metacassette8.png',
      'assets/Allproducts/MetaForm/metacassette/metacassette9.png',
      'assets/Allproducts/MetaForm/metacassette/metacassette10.png',
      'assets/Allproducts/MetaForm/metacassette/metacassette11.png',
      'assets/Allproducts/MetaForm/metacassette/metacassette12.png',

    ]

  }, {
    name: 'metaflute',
    images: ['assets/Allproducts/MetaForm/metaflute/metaflute1.jpg',
      'assets/Allproducts/MetaForm/metaflute/metaflute2.png',
      'assets/Allproducts/MetaForm/metaflute/metaflute3.jpg',
      'assets/Allproducts/MetaForm/metaflute/metaflute4.png',
      'assets/Allproducts/MetaForm/metaflute/metaflute5.png',
      'assets/Allproducts/MetaForm/metaflute/metaflute6.png',
      'assets/Allproducts/MetaForm/metaflute/metaflute7.png',
      'assets/Allproducts/MetaForm/metaflute/metaflute8.png',
      'assets/Allproducts/MetaForm/metaflute/metaflute9.png',
      'assets/Allproducts/MetaForm/metaflute/metaflute10.png',
      'assets/Allproducts/MetaForm/metaflute/metaflute11.png',
      'assets/Allproducts/MetaForm/metaflute/metaflute12.png',
      'assets/Allproducts/MetaForm/metaflute/metaflute13.png',
      'assets/Allproducts/MetaForm/metaflute/metaflute14.png',
      'assets/Allproducts/MetaForm/metaflute/metaflute15.png',
      'assets/Allproducts/MetaForm/metaflute/metaflute16.png',
    ]
  }, {
    name: 'metalouver',
    images: ['assets/Allproducts/MetaForm/metalouver/metalouver1.jpg',
      'assets/Allproducts/MetaForm/metalouver/metalouver2.png',
      'assets/Allproducts/MetaForm/metalouver/metalouver3.jpg',
      'assets/Allproducts/MetaForm/metalouver/metalouver4.png',
      'assets/Allproducts/MetaForm/metalouver/metalouver5.jpg',
      'assets/Allproducts/MetaForm/metalouver/metalouver6.png',
      'assets/Allproducts/MetaForm/metalouver/metalouver7.jpg',
    ]
  }, {
    name: 'metaplank',
    images: ['assets/Allproducts/MetaForm/metaplank/metaplank1.png',
      'assets/Allproducts/MetaForm/metaplank/metaplank2.png',
    ]
  }, {
    name: 'metapyramid',
    images: ['assets/Allproducts/MetaForm/metapyramid/metapyramid1.jpg',
      'assets/Allproducts/MetaForm/metapyramid/metapyramid2.png',
      'assets/Allproducts/MetaForm/metapyramid/metapyramid3.jpg',
      'assets/Allproducts/MetaForm/metapyramid/metapyramid4.png',
      'assets/Allproducts/MetaForm/metapyramid/metapyramid5.jpg',
      'assets/Allproducts/MetaForm/metapyramid/metapyramid6.png',
      'assets/Allproducts/MetaForm/metapyramid/metapyramid7.jpg',
      'assets/Allproducts/MetaForm/metapyramid/metapyramid8.png',
      'assets/Allproducts/MetaForm/metapyramid/metapyramid9.jpg',
      'assets/Allproducts/MetaForm/metapyramid/metapyramid10.jpg',
      'assets/Allproducts/MetaForm/metapyramid/metapyramid11.jpg',

    ]
  }, {
    name: 'metashingle',
    images: ['assets/Allproducts/MetaForm/metashingle/metashingle1.png',
      'assets/Allproducts/MetaForm/metashingle/metashingle2.png',
      'assets/Allproducts/MetaForm/metashingle/metashingle3.png',
      'assets/Allproducts/MetaForm/metashingle/metashingle4.png',
      'assets/Allproducts/MetaForm/metashingle/metashingle5.png',
      'assets/Allproducts/MetaForm/metashingle/metashingle6.png',
      'assets/Allproducts/MetaForm/metashingle/metashingle7.png',
      'assets/Allproducts/MetaForm/metashingle/metashingle8.png',
      'assets/Allproducts/MetaForm/metashingle/metashingle9.png',
      'assets/Allproducts/MetaForm/metashingle/metashingle10.png',
      'assets/Allproducts/MetaForm/metashingle/metashingle11.png',
    ]
  }, {
    name: 'SolidPanel',
    images: ['assets/Allproducts/MetaForm/SolidPanel/solid1.png',
      'assets/Allproducts/MetaForm/SolidPanel/solid2.png',
      'assets/Allproducts/MetaForm/SolidPanel/solid3.png',
      'assets/Allproducts/MetaForm/SolidPanel/solid4.png',
      'assets/Allproducts/MetaForm/SolidPanel/solid5.png',
    ]
  }, {
    name: 'metafold',
    images: ['assets/Allproducts/MetaFunction/metafold/metafold1.jpg',
      'assets/Allproducts/MetaFunction/metafold/metafold2.png',
      'assets/Allproducts/MetaFunction/metafold/metafold3.png',
    ]
  }, {
    name: 'metagate',
    images: ['assets/Allproducts/MetaFunction/metagate/metagate1.png',
      'assets/Allproducts/MetaFunction/metagate/metagate3.png',
    ]
  }, {
    name: 'metaslider',
    images: ['assets/Allproducts/MetaFunction/metaslider/metaslider1.png',
      'assets/Allproducts/MetaFunction/metaslider/metaslider2.png',
    ]
  }, {
    name: 'metablox',
    images: ['assets/Allproducts/MetaParametric/metablox/metablox1.png',
      'assets/Allproducts/MetaParametric/metablox/metablox2.png',
    ]
  }, {
    name: 'metacoin',
    images: ['assets/Allproducts/MetaParametric/metacoin/metacoin1.jpg',
      'assets/Allproducts/MetaParametric/metacoin/metacoin2.png',
      'assets/Allproducts/MetaParametric/metacoin/metacoin3.jpg',
      'assets/Allproducts/MetaParametric/metacoin/metacoin4.png',
      'assets/Allproducts/MetaParametric/metacoin/metacoin5.jpg',
      'assets/Allproducts/MetaParametric/metacoin/metacoin6.png',
      'assets/Allproducts/MetaParametric/metacoin/metacoin7.jpg',
      'assets/Allproducts/MetaParametric/metacoin/metacoin8.png',
      'assets/Allproducts/MetaParametric/metacoin/metacoin9.jpg',
      'assets/Allproducts/MetaParametric/metacoin/metacoin10.png',
      'assets/Allproducts/MetaParametric/metacoin/metacoin11.png',
      'assets/Allproducts/MetaParametric/metacoin/metacoin12.png',
      'assets/Allproducts/MetaParametric/metacoin/metacoin13.png',
      'assets/Allproducts/MetaParametric/metacoin/metacoin14.png',
      'assets/Allproducts/MetaParametric/metacoin/metacoin15.png',
      'assets/Allproducts/MetaParametric/metacoin/metacoin16.png',
      'assets/Allproducts/MetaParametric/metacoin/metacoin17.png',
      'assets/Allproducts/MetaParametric/metacoin/metacoin18.png',
    ]
  }, {
    name: 'metafin',
    images: ['assets/Allproducts/MetaParametric/metafin/metafin1.png',
      'assets/Allproducts/MetaParametric/metafin/metafin2.png',
      'assets/Allproducts/MetaParametric/metafin/metafin4.png',
      'assets/Allproducts/MetaParametric/metafin/metafin5.png',
      'assets/Allproducts/MetaParametric/metafin/metafin6.png',
      'assets/Allproducts/MetaParametric/metafin/metafin7.png',
      'assets/Allproducts/MetaParametric/metafin/metafin8.png',
    ]
  }, {
    name: 'metahydra',
    images: ['assets/Allproducts/MetaParametric/metahydra/metahydra1.png',
      'assets/Allproducts/MetaParametric/metahydra/metahydra2.png',
      'assets/Allproducts/MetaParametric/metahydra/metahydra4.png',
      'assets/Allproducts/MetaParametric/metahydra/metahydra5.png',
    ]
  }, {
    name: 'metasequin',
    images: ['assets/Allproducts/MetaParametric/metasequin/metasequin1.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin2.png',
      'assets/Allproducts/MetaParametric/metasequin/metasequin3.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin4.png',
      'assets/Allproducts/MetaParametric/metasequin/metasequin5.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin6.png',
      'assets/Allproducts/MetaParametric/metasequin/metasequin7.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin8.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin9.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin10.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin11.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin12.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin13.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin14.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin15.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin16.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin17.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin18.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin19.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin20.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin21.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin22.jpg',
      'assets/Allproducts/MetaParametric/metasequin/metasequin23.jpg',
    ]
  }, {
    name: 'metacopper',
    images: ['assets/Allproducts/MetaSurface/metacopper/metacopper1.png',
      'assets/Allproducts/MetaSurface/metacopper/metacopper2.png',
      'assets/Allproducts/MetaSurface/metacopper/metacopper3.png',
      'assets/Allproducts/MetaSurface/metacopper/metacopper4.png',
      'assets/Allproducts/MetaSurface/metacopper/metacopper5.png',
      'assets/Allproducts/MetaSurface/metacopper/metacopper6.png',
      'assets/Allproducts/MetaSurface/metacopper/metacopper7.png',
      'assets/Allproducts/MetaSurface/metacopper/metacopper8.png',
      'assets/Allproducts/MetaSurface/metacopper/metacopper10.png',
      'assets/Allproducts/MetaSurface/metacopper/metacopper11.png',
      'assets/Allproducts/MetaSurface/metacopper/metacopper12.png',
      'assets/Allproducts/MetaSurface/metacopper/metacopper13.png',
    ]
  }, {
    name: 'metacorten',
    images: ['assets/Allproducts/MetaSurface/metacorten/metacorten1.png',
      'assets/Allproducts/MetaSurface/metacorten/metacorten2.png',
      'assets/Allproducts/MetaSurface/metacorten/metacorten4.png',
      'assets/Allproducts/MetaSurface/metacorten/metacorten5.png',
      'assets/Allproducts/MetaSurface/metacorten/metacorten6.png',
    ]
  }
    , {
    name: 'metagrey',
    images: ['assets/Allproducts/MetaSurface/metagrey/metagrey1.png',
      'assets/Allproducts/MetaSurface/metagrey/metagrey2.png',
      'assets/Allproducts/MetaSurface/metagrey/metagrey3.png',
      'assets/Allproducts/MetaSurface/metagrey/metagrey4.png',
      'assets/Allproducts/MetaSurface/metagrey/metagrey5.png',
      'assets/Allproducts/MetaSurface/metagrey/metagrey7.png',
      'assets/Allproducts/MetaSurface/metagrey/metagrey8.png',
      'assets/Allproducts/MetaSurface/metagrey/metagrey9.png',
      'assets/Allproducts/MetaSurface/metagrey/metagrey10.png',
      'assets/Allproducts/MetaSurface/metagrey/metagrey11.png',
      'assets/Allproducts/MetaSurface/metagrey/metagrey12.png',
    ]
  }, {
    name: 'metapatina',
    images: ['assets/Allproducts/MetaSurface/metapatina/metapatina1.png',
      'assets/Allproducts/MetaSurface/metapatina/metapatina2.png',
      'assets/Allproducts/MetaSurface/metapatina/metapatina3.png',
      'assets/Allproducts/MetaSurface/metapatina/metapatina4.png',
      'assets/Allproducts/MetaSurface/metapatina/metapatina5.png',
      'assets/Allproducts/MetaSurface/metapatina/metapatina6.png',
      'assets/Allproducts/MetaSurface/metapatina/metapatina7.png',
      'assets/Allproducts/MetaSurface/metapatina/metapatina9.png',
      'assets/Allproducts/MetaSurface/metapatina/metapatina10.png',
    ]
  }
    , {
    name: 'metawood',
    images: ['assets/Allproducts/MetaSurface/metawood/metawood1.png',
      'assets/Allproducts/MetaSurface/metawood/metawood2.png',
      'assets/Allproducts/MetaSurface/metawood/metawood1.png',
      'assets/Allproducts/MetaSurface/metawood/metawood3.png',
      'assets/Allproducts/MetaSurface/metawood/metawood4.png',
      'assets/Allproducts/MetaSurface/metawood/metawood5.png',
      'assets/Allproducts/MetaSurface/metawood/metawood6.png',
      'assets/Allproducts/MetaSurface/metawood/metawood7.png',
      'assets/Allproducts/MetaSurface/metawood/metawood8.png',
      'assets/Allproducts/MetaSurface/metawood/metawood10.png',
      'assets/Allproducts/MetaSurface/metawood/metawood11.png',
      'assets/Allproducts/MetaSurface/metawood/metawood12.png',
      'assets/Allproducts/MetaSurface/metawood/metawood13.png',
    ]
  }
  ];

  const images = [
    { imgPath: 'assets/Allproducts/MetaForm/CascadingKeys/cascading1.png', imgText: 'Cascading Keys' },
    { imgPath: 'assets/Allproducts/MetaForm/metacassette/metacassette1.jpg', imgText: 'metacassette' },
    { imgPath: 'assets/Allproducts/MetaForm/metaflute/metaflute1.jpg', imgText: 'metaflute' },
    { imgPath: 'assets/Allproducts/MetaForm/metalouver/metalouver1.jpg', imgText: 'metalouver' },
    { imgPath: 'assets/Allproducts/MetaForm/metaplank/metaplank1.png', imgText: 'metaplank' },
    { imgPath: 'assets/Allproducts/MetaForm/metapyramid/metapyramid1.jpg', imgText: 'metapyramid' },
    { imgPath: 'assets/Allproducts/MetaForm/metashingle/metashingle1.png', imgText: 'metashingle' },
    { imgPath: 'assets/Allproducts/MetaForm/SolidPanel/solid1.png', imgText: 'SolidPanel' },
    { imgPath: 'assets/Allproducts/MetaFunction/metafold/metafold1.jpg', imgText: 'metafold' },
    { imgPath: 'assets/Allproducts/MetaFunction/metagate/metagate1.png', imgText: 'metagate' },
    { imgPath: 'assets/Allproducts/MetaFunction/metaslider/metaslider1.png', imgText: 'metaslider' },
    { imgPath: 'assets/Allproducts/MetaParametric/metablox/metablox1.png', imgText: 'metablox' },
    { imgPath: 'assets/Allproducts/MetaParametric/metacoin/metacoin1.jpg', imgText: 'metacoin' },
    { imgPath: 'assets/Allproducts/MetaParametric/metafin/metafin1.png', imgText: 'metafin' },
    { imgPath: 'assets/Allproducts/MetaParametric/metahydra/metahydra1.png', imgText: 'metahydra' },
    { imgPath: 'assets/Allproducts/MetaParametric/metasequin/metasequin1.jpg', imgText: 'metasequin' },
    { imgPath: 'assets/Allproducts/MetaSurface/metacopper/metacopper1.png', imgText: 'metacopper' },
    { imgPath: 'assets/Allproducts/MetaSurface/metacorten/metacorten1.png', imgText: 'metacorten' },
    { imgPath: 'assets/Allproducts/MetaSurface/metagrey/metagrey1.png', imgText: 'metagrey' },
    { imgPath: 'assets/Allproducts/MetaSurface/metapatina/metapatina1.png', imgText: 'metapatina' },
    { imgPath: 'assets/Allproducts/MetaSurface/metawood/metawood1.png', imgText: 'metawood' }
  ];


  function productClickHandler(index) {
    const selectedProduct = singleProductDetail[index];
    navigate('/single-product', { state: { selectedProduct } }); // Redirect to SingleProduct page
  }

  function filterImagesByCategory(category) {
    setSelectedCategory(category);
  }

  // Filter images based on selected category
  const filteredImages = selectedCategory
    ? images.filter((img) => img.imgPath.includes(selectedCategory))
    : images;

  return (
    <div className="container" style={{ marginTop: '100px' }}>
      <div className="row">
        <div className="col-9 xs-12">
          <div className="gallery-scroll">
            <div className="gallery">
              {filteredImages.map((img, index) => (
                <div key={index} className="gallery-item" onClick={() => productClickHandler(index)}>
                  <img src={`${process.env.PUBLIC_URL}/${img.imgPath}`} alt={`Gallery ${index}`} />
                  <div className="image-text">{img.imgText}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-12 sidebar-section">
          <h3>Our Products</h3>
          <div className="sidebar p-4">
            <ListGroup variant="flush">
              {/* <ListGroup.Item action variant="dark" style={{color: "#fff"}}>Section</ListGroup.Item> */}
              <ListGroup.Item action variant="dark" onClick={() => filterImagesByCategory('')}>
                All
              </ListGroup.Item>
              <ListGroup.Item action variant="dark" onClick={() => filterImagesByCategory('MetaParametric')}>
                MetaParametric
              </ListGroup.Item>
              <ListGroup.Item action variant="dark" onClick={() => filterImagesByCategory('MetaForm')}>
                MetaForm
              </ListGroup.Item>
              <ListGroup.Item action variant="dark" onClick={() => filterImagesByCategory('MetaFunction')}>
                MetaFunction
              </ListGroup.Item>
              <ListGroup.Item action variant="dark" onClick={() => filterImagesByCategory('MetaSurface')}>
                MetaSurfaces
              </ListGroup.Item>
            </ListGroup>
          </div>
          <button class="hover-button"><span>Build Your Dream</span></button>
        </div>
      </div>
    </div>
  );
};

export default Allproducts;
