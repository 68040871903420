import { React, useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, ListGroup } from 'react-bootstrap';
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa"; // Importing Instagram icon from react-icons
import { FaCube } from "react-icons/fa";
import './SingleProduct.css';


const SingleProduct = () => {

  const location = useLocation();
  const { selectedProduct } = location.state || {};

  const [clickedIndex, setClickedIndex] = useState(null);
  const gridRef = useRef < HTMLDivElement > (null)

  const handleImageClick = (index) => {
    setClickedIndex(clickedIndex === index ? null : index)
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (gridRef.current && !gridRef.current.contains(event.target)) {
        setClickedIndex(null)
      }
    }

    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, []);

  const isLastRow = (index) => {
    return index >= selectedProduct.images.length - (selectedProduct.images.length % 3 === 0 ? 3 : selectedProduct.images.length % 3);
  };

  return (
    <div className="container">
      <div className="row">
        {/* Gallery Section occupying col-9 */}
        <div className="col-9 xs-12">
          <div className="image-grid ">
            {selectedProduct.images.map((image, index) => (
              <div
                key={index}
                className={`grid-item ${isLastRow(index) ? 'last-row' : ''} ${clickedIndex === index ? 'active' : ''}`}
                onClick={() => {
                  handleImageClick(index)
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/${image}`} className="grid-image" />
              </div>
            ))}
          </div>
        </div>
        {/* Empty Space col-2 */}
        <div className="col-md-3 col-sm-12 sidebar-section" style={{ marginTop: '100px' }}>
          <h3 style={{ fontWeight: 'bold' }}>Metacoin</h3>
          <div className="sidebar p-4 bg-darkrounded">
            <ListGroup variant="flush">
              <ListGroup.Item action variant="dark" style={{ fontSize: '14px' }}>A fusion of artistry and engineering, unfolds through patented technology.

                Crafted from pure Aluminum Alloy, enriched with Meta Surface Finishes, each panel resists time, metamorphosing into a timeless symphony of design and light making it a durable canvas for visionary dreams.</ListGroup.Item>
            </ListGroup>
          </div>
          
          <button className="transition-button">
            <span className="icon">
              <FaYoutube />
            </span>
            <span className="text"><span className="icon">
              <FaYoutube />
            </span> See On YouTube </span>
          </button>
          <button className="transition-button">
            <span className="icon">
              <FaInstagram />
            </span>
            <span className="text"><span className="icon">
              <FaInstagram />
            </span> See on 
            Instagram </span>
          </button>
          <button className="transition-button">
            <span className="icon">
              <FaCube />
            </span>
            <span className="text"><span className="icon">
              <FaCube />
            </span> Explore AR</span>
          </button>
          <button class="hover-button"><span>Build Your Dream</span></button>
        </div>
      </div>
    </div >
  );
};

export default SingleProduct;