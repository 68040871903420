import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './Contact.css';


const Contact = () => {
  return (
    <Container fluid className="vh-100 bg-dark text-white">
      <Row className="h-100">
        {/* Left Section */}
        <Col md={6} className="d-flex flex-column justify-content-center align-items-start p-5">
          <h1 className="display-4 contactus-text">We'd Love to Connect with You.</h1>
          <p className="lead-contact">Share your vision, and let's create something amazing together.</p>
        </Col>

        {/* Right Section */}
        <Col md={6} className="d-flex flex-column justify-content-center align-items-center">
          <Form className="w-75">
                <Row>
                    <Col md={6}>
                    <Form.Group controlId="formName">
              <Form.Control type="text" placeholder="Name" className="bg-contact form-text border-0 mb-3" />
            </Form.Group>
                    </Col>
                    <Col md={6}>

            <Form.Group controlId="formEmail">
              <Form.Control type="email" placeholder="Email" className="bg-contact form-text border-0 mb-3" />
            </Form.Group>
                    </Col>
                </Row>
            
            <Form.Group controlId="formMessage">
              <Form.Control as="textarea" rows={4} placeholder="Message" className="bg-contact form-text border-0 mb-3" />
            </Form.Group>
            <Button variant="dark" type="submit" className="btn-block">
              Send
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
